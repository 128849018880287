
interface MSDoNotTracker extends Navigator {
	msDoNotTrack: string|undefined
}

interface MSTrackingProtectionEnabler extends External {
	msTrackingProtectionEnabled: () => boolean
}

interface DoNotTrackerWindow extends Window {
	doNotTrack: string
}

/**
 * Ponyfill for navigator.doNotTrack
 */
export function doNotTrack(): string|null {
	try {
		if ( 'doNotTrack' in window ) {
			if ( '1' === ( ( window as unknown ) as DoNotTrackerWindow ).doNotTrack ) {
				return '1';
			}
		}

		if ( 'navigator' in window ) {
			if ( '1' === navigator.doNotTrack ) {
				return '1';
			}

			if ( 'yes' === navigator.doNotTrack ) {
				return '1';
			}

			// eslint-disable-next-line eqeqeq
			if ( 'msDoNotTrack' in navigator && '1' == ( navigator as MSDoNotTracker ).msDoNotTrack ) {
				return '1';
			}
		}

		if ( 'external' in window && 'msTrackingProtectionEnabled' in window.external ) {
			const trackingProtector = ( window.external as MSTrackingProtectionEnabler );
			if (
				( 'function' === typeof trackingProtector.msTrackingProtectionEnabled ) &&
				trackingProtector.msTrackingProtectionEnabled()
			) {
				return '1';
			}
		}
	} catch ( err ) {
		console.warn( err );

		return null;
	}

	return null;
}
