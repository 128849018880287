import { MrModalDialog } from '@mrhenry/wp--mr-interactive';

class MrArtworkOverlay extends MrModalDialog {

	override async willOpen() {
		await super.willOpen();
		document.body.classList.add( 'is-showing-modal-dialog' );
	}

	override async didOpen() {
		const artwork = this.querySelector( '.artwork-overlay__inner__image' );
		if ( !artwork ) {
			return;
		}

		const container = this.querySelector( '.artwork-overlay__inner' );
		if ( !container ) {
			return;
		}

		const src = artwork.getAttribute( 'lazy-src' );
		if ( !src ) {
			return;
		}

		const imageWidth = container.getBoundingClientRect().width;
		const imageHeight = container.getBoundingClientRect().height;

		let imageWidthCeil = 0;
		let imageHeightCeil = 0;

		imageWidthCeil = Math.ceil( imageWidth / 100 ) * 100 * ( window.devicePixelRatio ?? 1 );
		imageHeightCeil = Math.ceil( imageHeight / 100 ) * 100 * ( window.devicePixelRatio ?? 1 );

		const w = imageWidthCeil.toString();
		const h = imageHeightCeil.toString();

		const url = new URL( src );

		url.searchParams.append( 'w', w );
		url.searchParams.append( 'h', h );

		artwork.setAttribute( 'src', url.href );
		artwork.removeAttribute( 'lazy-src' );
	}

	override async willClose() {
		await super.willClose();
		document.body.classList.remove( 'is-showing-modal-dialog' );
	}

	override openAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						opacity: '0',
					},
					{
						opacity: '1',
					},
				],
				{
					duration: 320,
					easing: 'linear',
					fill: 'forwards',
				}
			),
		];
	}

	override closeAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						opacity: '1',
					},
					{
						opacity: '0',
					},
				],
				{
					duration: 288,
					easing: 'linear',
					fill: 'forwards',
				}
			),
		];
	}
}

customElements.define( 'mr-artwork-overlay', MrArtworkOverlay );
