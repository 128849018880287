import * as Cookie from '../../cookie/cookie';

export function isEnabledInGTM( key: string ): boolean {
	const match = document.cookie.match( new RegExp( '(^| )mr_prv_gtm=([^;]+)' ) );
	let records: Array<{ key: string, value: string }> = [];
	if ( match && match[2] ) {
		records = Cookie.unpackRecords( match[2] );
	}

	records = records.filter( ( record ) => {
		return record.key === key && '1' === record.value;
	} );

	return 0 < records.length;
}

export function isDisabledInGTM( key: string ): boolean {
	const match = document.cookie.match( new RegExp( '(^| )mr_prv_gtm=([^;]+)' ) );
	let records: Array<{ key: string, value: string }> = [];
	if ( match && match[2] ) {
		records = Cookie.unpackRecords( match[2] );
	}

	records = records.filter( ( record ) => {
		return record.key === key && '-1' === record.value;
	} );

	return 0 < records.length;
}

export function enableInGTM( key: string ): void {
	const match = document.cookie.match( new RegExp( '(^| )mr_prv_gtm=([^;]+)' ) );
	let records: Array<{ key: string, value: string }> = [];
	if ( match && match[2] ) {
		records = Cookie.unpackRecords( match[2] );
	}

	records = records.filter( ( record ) => {
		return record.key !== key;
	} );

	records.push( {
		key: key,
		value: '1',
	} );

	const expires = window.Mr_Prv_GTM_Helpers.cookieExpiration();

	if ( window.Mr_Prv_GTM_Helpers.cookieDomain() ) {
		document.cookie = `mr_prv_gtm=${Cookie.packRecords( records )}; domain=${window.Mr_Prv_GTM_Helpers.cookieDomain()}; path=/; secure; samesite=lax; expires=` + expires.toUTCString();
	} else {
		document.cookie = `mr_prv_gtm=${Cookie.packRecords( records )}; path=/; secure; samesite=lax; expires=` + expires.toUTCString();
	}
}

export function disableInGTM( key: string ): void {
	const match = document.cookie.match( new RegExp( '(^| )mr_prv_gtm=([^;]+)' ) );
	let records: Array<{ key: string, value: string }> = [];
	if ( match && match[2] ) {
		records = Cookie.unpackRecords( match[2] );
	}

	records = records.filter( ( record ) => {
		return record.key !== key;
	} );

	records.push( {
		key: key,
		value: '-1',
	} );

	const expires = window.Mr_Prv_GTM_Helpers.cookieExpiration();

	if ( window.Mr_Prv_GTM_Helpers.cookieDomain() ) {
		document.cookie = `mr_prv_gtm=${Cookie.packRecords( records )}; domain=${window.Mr_Prv_GTM_Helpers.cookieDomain()}; path=/; secure; samesite=lax; expires=` + expires.toUTCString();
	} else {
		document.cookie = `mr_prv_gtm=${Cookie.packRecords( records )}; path=/; secure; samesite=lax; expires=` + expires.toUTCString();
	}
}
