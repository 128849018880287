import '@mrhenry/wp--bugsnag-config';

/* Modules */
import './modules/artwork-overlay';
import './modules/input-sink';
import './modules/mr-carousel.ts';
import './modules/navigation-overlay';

import { PrivacyControls, initMenuButtons } from '@mrhenry/wp--mr-interactive';

PrivacyControls.setupPrivacyAwareEmbedders();
PrivacyControls.setupPrivacyAwareGTM();

requestAnimationFrame( () => {
	initMenuButtons();
} );
