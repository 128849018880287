import { doNotTrack } from '../do-not-track/do-not-track';
import { embedderIsEnabled, enableEmbedder, disableEmbedder } from './embeds';

declare global {
	interface Window {
		Mr_Prv_Embedder_Helpers: {
			isEnabled( key: string ): boolean
			enable( key: string ): void
			disable( key: string ): void
			cookieExpiration(): Date
			cookieDomain(): string|null
		};
	}
}

export function setupPrivacyAwareEmbedders(): void {
	window.Mr_Prv_Embedder_Helpers = {
		isEnabled: embedderIsEnabled,
		enable: enableEmbedder,
		disable: disableEmbedder,
		cookieExpiration: (): Date => {
			const d = new Date();
			d.setTime( d.getTime() + 90 * 24 * 60 * 60 * 1000 );

			return d;
		},
		cookieDomain: (): string|null => {
			return null;
		},
	};

	// Make sure all content is unblocked eventually
	document.addEventListener( 'readystatechange', () => {
		document.querySelectorAll( '[mr-prv-embedder-blocked]' ).forEach( ( el ) => {
			const key = el.getAttribute( 'mr-prv-embedder-blocked' );
			if ( !key ) {
				return;
			}

			if ( embedderIsEnabled( key ) ) {
				el.removeAttribute( 'mr-prv-embedder-blocked' );
				el.removeAttribute( 'tabindex' );
			}
		} );

		document.querySelectorAll( '[mr-prv-embedders-control]' ).forEach( ( el ) => {
			if ( !el || !( el instanceof HTMLInputElement ) ) {
				return;
			}

			if ( doNotTrack() ) {
				el.setAttribute( 'do-not-track', '1' );
			}

			const enabled = embedderIsEnabled( el.name );

			if ( enabled && 'true' === el.value ) {
				el.checked = true;

				return;
			}

			if ( !enabled && 'false' === el.value ) {
				el.checked = true;

				return;
			}

			el.checked = false;
		} );
	} );

	window.addEventListener( 'change', ( e ) => {
		if ( !e.target || !( e.target instanceof HTMLInputElement ) ) {
			return;
		}

		const input = e.target;
		if ( !input.hasAttribute( 'mr-prv-embedders-control' ) ) {
			return;
		}

		if ( 'true' === input.value ) {
			enableEmbedder( input.name );

			return;
		}

		disableEmbedder( input.name );
	} );
}
