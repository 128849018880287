import { doNotTrack } from '../do-not-track/do-not-track';
import { isEnabledInGTM, isDisabledInGTM, enableInGTM, disableInGTM } from './gtm';

declare global {
	interface Window {
		Mr_Prv_GTM_Helpers: {
			isEnabled( key: string ): boolean
			isDisabled( key: string ): boolean
			enable( key: string ): void
			disable( key: string ): void
			cookieExpiration(): Date
			cookieDomain(): string|null
		};
	}
}

export function setupPrivacyAwareGTM(): void {
	window.Mr_Prv_GTM_Helpers = {
		isEnabled: isEnabledInGTM,
		isDisabled: isDisabledInGTM,
		enable: enableInGTM,
		disable: disableInGTM,
		cookieExpiration: (): Date => {
			const d = new Date();
			d.setTime( d.getTime() + 90 * 24 * 60 * 60 * 1000 );

			return d;
		},
		cookieDomain: (): string|null => {
			return null;
		},
	};

	// Make sure all content is unblocked eventually
	document.addEventListener( 'readystatechange', () => {
		document.querySelectorAll( '[mr-prv-gtm-control]' ).forEach( ( el ) => {
			if ( !el || !( el instanceof HTMLInputElement ) ) {
				return;
			}

			if ( doNotTrack() ) {
				el.setAttribute( 'do-not-track', '1' );
			}

			// Explicitly enabled
			const enabled = isEnabledInGTM( el.name );
			if ( enabled ) {
				if ( 'true' === el.value ) {
					el.checked = true;

					return;
				}

				if ( 'false' === el.value ) {
					el.checked = false;

					return;
				}

				return;
			}

			// Explicitly disabled
			const disabled = isDisabledInGTM( el.name );
			if ( disabled ) {
				if ( 'true' === el.value ) {
					el.checked = false;

					return;
				}

				if ( 'false' === el.value ) {
					el.checked = true;

					return;
				}

				return;
			}

			// Implicitly enabled
			if ( 'enabled' === el.getAttribute( 'mr-prv-gtm-control-default' ) ) {
				if ( 'true' === el.value ) {
					el.checked = true;

					return;
				}

				if ( 'false' === el.value ) {
					el.checked = false;

					return;
				}

				return;
			}

			// Implicitly disabled
			if ( 'true' === el.value ) {
				el.checked = false;

				return;
			}

			if ( 'false' === el.value ) {
				el.checked = true;

				return;
			}
		} );
	} );

	window.addEventListener( 'change', ( e ) => {
		if ( !e.target || !( e.target instanceof HTMLInputElement ) ) {
			return;
		}

		const input = e.target;
		if ( !input.hasAttribute( 'mr-prv-gtm-control' ) ) {
			return;
		}

		if ( 'true' === input.value ) {
			enableInGTM( input.name );

			return;
		}

		disableInGTM( input.name );
	} );
}
