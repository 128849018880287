// Naive implementation of key/value object packing for cookies used by Google/Youtube/...
// - url safe
// - compact
//
// Note : doesn't do any input validation.
// If your inputs are bad this will bite you and I am ok with that.

export function unpackRecords( str: string ): Array<{ key: string, value: string }> {
	const records: Array<{key: string, value:string}> = [];

	str.split( '.' ).forEach( ( record ) => {
		const pair = record.split( '+' );
		if ( !pair || !pair.length ) {
			return;
		}

		records.push( {
			key: pair[0],
			value: pair[1] || '',
		} );
	} );

	return records;
}

export function packRecords( records: Array<{ key: string, value: string }> ): string {
	const out: Array<string> = [];

	records.forEach( ( record ) => {
		out.push( record.key + '+' + record.value );
	} );

	return out.join( '.' );
}
